/** @jsx jsx */
import ProductsPage from '@bottlebooks/gatsby-theme-event/src/components/ProductsPage/ProductsPage';
import { graphql } from 'gatsby';
import deepMerge from 'lodash/merge';
import { jsx } from 'theme-ui';

export default function ProductsTemplate({
  data,
  pageContext,
  location,
  navigate,
}) {
  const { event, site, products } = afterQuery(data);
  const { filterPriceRange } = event || {};

  return (
    <ProductsPage
      {...pageContext}
      event={event}
      products={products}
      siteTitle={site?.siteMetadata?.title}
      facetConfig={{
        filterPriceRange,
        ...pageContext,
      }}
      location={location}
      navigate={navigate}
    />
  );
}

export const pageQuery = graphql`
  fragment predhomme_ProductsTemplate_Event on Bottlebooks_Event {
    collectionId: eventId
    ...bb_ProductsPageBase_Collection
    registeredProducts {
      nodes {
        productId
        ...bb_ProductsPageBase
        ...predhomme_productFilters
      }
    }
  }

  query predhomme_ProductsTemplate(
    $locale: Bottlebooks_ContentLocale
    $filter: ProductFilterInput
    $sort: ProductSortInput
    $localeString: String
  ) {
    bottlebooks {
      oregon: event(eventId: "62ed7a9df28b1e10cc33ec08", locale: $locale) {
        ...predhomme_ProductsTemplate_Event
      }
      washington: event(eventId: "6352f9a1e8559f3adf7281f6", locale: $locale) {
        ...predhomme_ProductsTemplate_Event
      }
    }

    firstProducts: allProduct(filter: $filter, sort: $sort, limit: 10) {
      nodes {
        ...ProductsPage_first
      }
    }
    products: allProduct(filter: $filter, sort: $sort) {
      nodes {
        productId
        slug
        exhibitor {
          slug
        }
        ...ProductsPage
      }
    }
    event(locale: { eq: $localeString }) {
      ...ProductsPage_Event
      filterPriceRange
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

function afterQuery({ bottlebooks, event, firstProducts, products, site }) {
  // Combine exhibitors from all events, indicating which event they are in
  const events = Object.values(bottlebooks);

  // To-do: apply dedupe logic to registeredBrands and registrations
  const bb_event = events[0];
  // const bb_products = bb_event?.registeredProducts?.nodes;
  const bb_products = mergeBbProducts(events);
  const combinedProducts = products.nodes?.map((product, index) => {
    const refinedData = firstProducts?.nodes[index];
    const bb_registeredProduct = bb_products?.find(
      ({ productId }) => productId === product.productId
    );
    const productMerged = deepMerge(
      {},
      product,
      bb_registeredProduct?.product,
      bb_registeredProduct,
      refinedData,
      { venue: bb_registeredProduct?.highlights?.values?.[0] }
    );
    return productMerged;
  });
  return {
    event: { ...event, ...bb_event },
    site,
    products: combinedProducts,
  };
}

function mergeBbProducts(events) {
  const products = events.reduce((acc, event) => {
    const { registeredProducts } = event;
    const productNodes = registeredProducts?.nodes || [];

    productNodes.map((product) => {
      const { productId } = product;
      if (acc[productId]) {
        acc[productId].collectionIds.push(event.collectionId);
        acc[productId].standNames.push({
          collectionId: event.collectionId,
          standName: product.stand?.name,
        });
      } else
        acc[productId] = {
          ...product,
          collectionIds: [event.collectionId],
          standNames: [
            {
              collectionId: event.collectionId,
              standName: product.stand?.name,
            },
          ],
        };
    });
    return acc;
  }, {});

  return Object.values(products);
}
